import { WizardSteps } from '@constants/index';

export type User = {
  customer: ICustomer;
  id?: string | undefined; // customer-id
  coadjuteParticipantId?: string;
  properties: Address[];
};

export type ProfileState = {
  value: User | null;
  isFetching: boolean;
  fetchComplete: boolean;
  errorOccurred: boolean;
  errorStatusCode?: number;
};

export enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum MoveType {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  TENANCY = 'RENTER',
}

export enum MoveStatus {
  COMPLETED = 'COMPLETED',
  UNKNOWN = 'UNKNOWN',
}

export type Task = {
  id: string;
  name: string;
  uiName: string;
  step: WizardSteps;
  status: TaskStatus;
  createdAt?: string;
  updatedAt?: string;
  completedAt?: string;
};

export type Address = {
  propertyId: string;
  addressLine1: string; // "1 The Road"
  addressLine2?: string; // "1 The Road"
  postcode: string; // "AB1 2CD"
  createdAt: string; // "2024-08-02T12:42:40.279522Z"
  updatedAt: string; // "2024-08-02T12:42:40.279522Z"
  city: string; // "London"
  country: string; // "GB"
  county?: string; // "London"
  status: MoveType; // "TENANCY" or "UNKNOWN"
  moveType: MoveType; // "TENANCY" or "UNKNOWN"
  startDate?: string; // "2024-08-02"
  endDate?: string; // "2025-08-02"
  tasks: Task[];
  moveStatus: MoveStatus; // "IN_PROGRESS" or "COMPLETED"
  partnerCode?: string; // "Agent Name" i.e CHESTERTONS, MCGRATH, CROFT
  whatsAppLink?: string; // "https://wa.me/447123456789"
  coordinates: {
    latitude: number;
    longitude: number;
  };
};
