import { useEffect, useState } from 'react';

import { LinkButton } from '@components/Button';
import { AusUtilitiesSignpost } from '@components/Signpost';
import { Spinner } from '@components/Spinner';

import { ApiRoutes } from '@constants/index';

import customAxios from '@utils/customAxios';

import { useMoveWizard } from '../..';

interface LeadResponse {
  leadId: number;
  customerId: string;
  signupLink: string;
  message: string;
}

type GetMoveReadyProps = {
  propertyId: string;
};

export const GetMoveReady = ({ propertyId }: GetMoveReadyProps) => {
  const [madeRequest, setMadeRequest] = useState<boolean>(false);
  const [iframeSrc, setIframeSrc] = useState<string>('');
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  const { activeIndex } = useMoveWizard();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customAxios.post<LeadResponse>(
          ApiRoutes.AUSTRALIA_UTILITIES,
          {
            propertyId,
          },
          {
            withCredentials: true,
          }
        );

        if (!!response?.data?.signupLink) {
          setIframeSrc(response.data.signupLink);
        }
      } catch (error) {
        console.error('Error submitting:', error);
        setErrorOccured(true);
      } finally {
        setMadeRequest(true);
      }
    };

    if (activeIndex === 6) {
      fetchData();
    }
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col items-center gap-10 px-5 sm:px-10">
      <AusUtilitiesSignpost />
      {!madeRequest && (
        <div className="flex h-96 w-full items-center justify-center">
          <Spinner colour="black" />
        </div>
      )}
      {madeRequest && errorOccured && (
        <div className="text-center font-medium text-red-500">
          An error occured while submitting your details. Please try again later.
        </div>
      )}
      {madeRequest && iframeSrc && (
        <div className="flex h-auto w-full items-center justify-center">
          <iframe title="Compare & Connect" src={iframeSrc} className="h-96 w-full" />
        </div>
      )}
    </div>
  );
};
