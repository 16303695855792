import L from 'leaflet';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import DefaultImage from '@images/default_image_05-2024.webp';

import icon from './icon.svg';

import 'tailwindcss/tailwind.css';
import 'leaflet/dist/leaflet.css';

const customIcon = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconSize: [40, 54], // Adjust the size as needed
  iconAnchor: [17, 54], // Adjust the anchor as needed
  popupAnchor: [0, -54],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [54, 54],
  shadowAnchor: [15, 55],
});

interface MapProps {
  coordinates?: {
    latitude: number;
    longitude: number;
  };
}

export const Map = ({ coordinates }: MapProps) => {
  const { latitude, longitude } = coordinates || { latitude: 0, longitude: 0 };

  if (!coordinates) {
    return (
      <div
        style={{ backgroundImage: `url(${DefaultImage})` }}
        className="relative z-10 w-full rounded-lg bg-cover bg-center pb-[66.66%] md:mt-0"
        data-testid="home-image"
      />
    );
  }

  return (
    <div className="relative z-0 h-full w-full">
      <MapContainer
        center={[latitude, longitude]}
        zoom={15}
        className="h-full w-full"
        zoomControl={false}
        dragging={false}
        touchZoom={false}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
          // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[latitude, longitude]} icon={customIcon} />
      </MapContainer>
    </div>
  );
};
