import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type ProductProps = {
  title: string;
  offerText?: string;
  image: ReactNode;
  price?: number;
  link: string;
  single?: boolean;
};

export const Product = ({ title, offerText, image, price, link, single }: ProductProps) => {
  const variableStyles = single ? 'rounded-md' : '';

  return (
    <div
      key={title}
      className={`relative flex w-full flex-col gap-4 overflow-hidden rounded-lg border border-gray-200 bg-white pb-5 text-primary shadow-homeLargeLight ${variableStyles}`}
      data-testid="product"
    >
      {offerText && (
        <span className="absolute -top-2 left-2 rounded-full bg-primary px-2 py-1 text-xs font-medium text-white">
          {offerText}
        </span>
      )}
      <div className="w-full">{image}</div>
      <div className="flex flex-col items-center gap-3 px-4">
        <h3 className="text-center text-xl font-medium">{title}</h3>
        <div className="flex w-full items-center justify-between gap-2 font-medium">
          {price && <span>from £{price}</span>}
          <Link
            to={link}
            target="_blank"
            className="rounded-full bg-primary px-5 py-2 text-sm text-white"
            rel="noreferrer"
          >
            Shop now
          </Link>
        </div>
      </div>
    </div>
  );
};
