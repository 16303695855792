import { WizardSteps } from '@constants/index';

import { Address } from '@features/profile/profile.types';

import {
  MapPin,
  Complete as CompleteIcon,
  Removals as RemovalsIcon,
  Bulb,
  Conveyancing as ConveyancingIcon,
  Insurance as InsuranceIcon,
  Dolla,
  Payment,
} from '@images/icons';

import { Complete } from './cards/Complete';
import { Conveyancing } from './cards/Conveyancing';
import { Exchange } from './cards/Exchange';
import { Finances } from './cards/Finances';
import { FindAProperty } from './cards/FindAProperty';
import { GetMoveReady } from './cards/GetMoveReady';
import { Insurance } from './cards/Insurance';
import { Removals } from './cards/Removals';

export const useWizardAus = (property: Address) => {
  const propertyId = property?.propertyId ?? '';

  const whatsAppLink = property.whatsAppLink || '';

  return [
    {
      id: WizardSteps.findProperty,
      icon: <MapPin className="h-10 w-10 fill-brands-mcgrath-primary" />,
      title: 'Find a property',
      content: <FindAProperty property={property} />,
      tasks:
        property?.tasks?.filter(
          task => task.step === WizardSteps.findProperty || task.step === WizardSteps.saleAgreed
        ) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.conveyancing,
      icon: <ConveyancingIcon className="h-10 w-10 fill-brands-mcgrath-primary" />,
      title: 'Conveyancing',
      content: <Conveyancing />,
      tasks: property?.tasks?.filter(task => task.step === WizardSteps.conveyancing) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.contracts,
      icon: <Dolla className="h-10 w-10 fill-brands-mcgrath-primary" />,
      title: 'Finances',
      content: <Finances />,
      tasks: property?.tasks?.filter(task => task.step === WizardSteps.finances) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.insurance,
      icon: <InsuranceIcon className="h-10 w-10 fill-brands-mcgrath-primary" />,
      title: 'Insurance',
      content: <Insurance propertyId={propertyId} />,
      tasks: property?.tasks?.filter(task => task.step === WizardSteps.insurance) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.exchange,
      icon: <Payment className="h-10 w-10 fill-brands-mcgrath-primary" />,
      title: 'Exchange',
      content: <Exchange />,
      tasks: property?.tasks?.filter(task => task.step === WizardSteps.exchange) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.removals,
      icon: <RemovalsIcon className="h-10 w-10 fill-brands-mcgrath-primary" />,
      title: 'Removals',
      content: <Removals />,
      tasks: property?.tasks?.filter(task => task.step === WizardSteps.removals) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.getMoveReady,
      icon: <Bulb className="h-10 w-10 fill-brands-mcgrath-primary" />,
      title: 'Utilities & Broadband',
      content: <GetMoveReady propertyId={propertyId} />,
      tasks: property?.tasks?.filter(task => task.step === WizardSteps.getMoveReady) ?? [],
      propertyId,
    },
    {
      id: WizardSteps.complete,
      icon: <CompleteIcon className="w-14 fill-brands-mcgrath-primary" />,
      title: 'Move In',
      content: <Complete whatsAppLink={whatsAppLink} />,
      tasks: property?.tasks?.filter(task => task.step === WizardSteps.complete) ?? [],
      propertyId,
    },
  ];
};
