import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Product } from '@components/Product';
import { Signpost } from '@components/Signpost';

import { BlogLinks, ProductLinks } from '@constants/index';

import { MoveType } from '@features/profile/profile.types';

import EnterpriseLogo from '@images/enterprise_logo.webp';
import EnterpriseVan from '@images/enterprise_van.webp';
import { TickIsolated } from '@images/icons';
import PackingCategory from '@images/packing_category.webp';
import RemovalsAnyVan from '@images/removals-card_anyvan.png';
import RemovalsBishops from '@images/removals-card_bishops.png';
import RemovalsHumphreys from '@images/removals-card_humphreys.png';

import { ContentBlock } from '../../components/ContentBlock';

type Props = {
  moveType: string;
};

export const Removals = ({ moveType }: Props) => {
  const anyvanTitle =
    moveType === MoveType.TENANCY ? 'Looking for help moving?' : 'Our trusted moving partner';

  const enterpriseTitle =
    moveType === MoveType.TENANCY ? 'Doing it yourself?' : 'Looking for van hire instead?';
  return (
    <>
      <div className="flex flex-col gap-7 px-7 md:gap-5 md:px-10">
        <div className="flex flex-col items-center gap-7 pb-5 md:flex-row md:items-start md:gap-14">
          <div className="basis-1/2 pt-8">
            <Signpost subtitle="To do" title="Book your removals or van hire">
              <div
                className={twMerge(
                  'flex',
                  moveType === MoveType.TENANCY ? 'flex-col-reverse' : 'flex-col'
                )}
              >
                <div className="flex flex-col items-start gap-6">
                  <p>
                    Our reliable partners are on hand to make this your easiest move yet with over
                    100 years experience.
                  </p>
                  <div className="flex items-center gap-6 py-2">
                    <img src={RemovalsHumphreys} alt="Humphreys" className="w-[122px]" />
                    <img src={RemovalsBishops} alt="Bishops" className="w-[68px]" />
                    <img src={RemovalsAnyVan} alt="AnyVan" className="w-[110px]" />
                  </div>
                  <ul className="flex flex-col gap-3">
                    <li className="flex items-center gap-2">
                      <TickIsolated className="h-5 fill-primary" />
                      Dedicated support
                    </li>
                    <li className="flex items-center gap-2">
                      <TickIsolated className="h-5 fill-primary" />
                      Free compensation cover
                    </li>
                    <li className="flex items-center gap-2">
                      <TickIsolated className="h-5 fill-primary" />
                      Driver tracking
                    </li>
                  </ul>
                  <Link
                    to={ProductLinks.REQUEST_CHAT_REMOVALS}
                    target="_blank"
                    className="button-animate rounded-xl px-10 py-3 font-medium"
                    rel="noreferrer"
                  >
                    Request a quote
                  </Link>
                </div>
              </div>
            </Signpost>
          </div>
          <hr className="w-full border-stone-200 md:hidden" />
          <div className="basis-1/2">
            <ContentBlock
              title="Why use a Home partner?"
              content="Make your move hassle-free with professional moving services. Our reliable partners offer simple 'man and van' services through to a white glove approach with everything packed for you and unpacked in your new home."
              link={BlogLinks.REMOVALS}
              buttonText="Read our guide to removals"
              showBoxOnDesktop
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-14 border-t border-stone-200 pb-8 pt-12 md:flex-row md:items-start">
          <div className="flex basis-1/2 flex-col items-center gap-6">
            <h4 className="text-center text-2xl font-medium">{enterpriseTitle}</h4>
            <div className="flex flex-col items-center gap-0 rounded-lg border border-stone-200 p-5 shadow-homeLargeLight md:p-6">
              <img
                src={EnterpriseVan}
                alt="Enterprise Van"
                className="w-full max-w-[266px] object-cover"
              />
              <h5 className="mb-6 text-center text-xl font-medium">
                10% off Van hire at Enterprise
              </h5>
              <div className="flex w-full items-center justify-around gap-5">
                <img src={EnterpriseLogo} alt="Enterprise" className="w-44" />
                <Link
                  className="font-base w-full rounded-xl bg-primary px-8 py-3 text-center font-medium text-white sm:w-auto md:w-full"
                  to={ProductLinks.REMOVALS_ENTERPRISE}
                  target="_blank"
                  rel="noreferrer"
                >
                  See prices
                </Link>
              </div>
            </div>
          </div>
          <hr className="w-full border-stone-200 md:hidden" />
          <div className="flex basis-1/2 flex-col gap-3">
            <h4 className="text-2xl font-medium leading-none">Shop packing supplies</h4>
            <p className="max-w-md pb-5">
              From boxes and tape to cleaning supplies and everything in-between.
            </p>
            <div className="max-w-md">
              <Product
                title="Packing supplies kit with all your essentials"
                image={<img src={PackingCategory} alt="Packing supplies" className="w-full" />}
                price={46.99}
                link={ProductLinks.STORE.PACKING_SUPPLIES}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
