import { AusCompleteSignpost, AusWhatsAppSignpost } from '@components/Signpost';

type Props = {
  whatsAppLink?: string;
};

export const Complete = ({ whatsAppLink }: Props) => {
  return (
    <div className="flex flex-col items-center gap-10 px-7 md:flex-row md:items-start md:gap-14 md:px-10">
      <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
        <AusCompleteSignpost />
      </div>
      <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
        <AusWhatsAppSignpost link={whatsAppLink || ''} />
      </div>
    </div>
  );
};
