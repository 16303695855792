import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  HOME_EMAIL,
  HOME_PHONE,
  HOME_PHONE_FORMATTED,
  HOME_WHATSAPP,
  HOME_WHATSAPP_FORMATTED,
  Routes,
} from '@constants/index';

import HomeTeamImage from '@images/home-concierge.jpg';
import { Chat, Envelope, Phone, WhatsApp } from '@images/icons';


interface ContactLink {
  href: string;
  text: string;
}

interface ContactDetailProps {
  icon: React.ElementType;
  name: string;
  link: ContactLink;
}

const ContactDetail = ({ icon: Icon, name, link }: ContactDetailProps) => (
  <div className="flex flex-col items-center gap-2 lg:items-start">
    <span className="flex h-10 w-10 items-center justify-center">
      <Icon className="fill-white" />
    </span>
    <h3 className="pt-2 text-xl font-medium">{name}</h3>
    <Link
      to={link.href}
      rel="noreferrer"
      className="w-full overflow-hidden text-ellipsis text-center font-light underline hover:no-underline lg:text-left"
    >
      {link.text}
    </Link>
  </div>
);

interface HomeTeamProps {
  hideChat?: boolean;
  email?: string;
  phone?: string;
  livechat?: string;
  whatsapp?: string;
}

export const HomeTeam = ({ hideChat, email, phone, livechat, whatsapp }: HomeTeamProps) => {
  const contactDetails: ContactDetailProps[] = [
    {
      icon: Chat,
      name: 'Live Chat',
      link: {
        href: livechat ?? Routes.CHAT,
        text: 'Start chatting',
      },
    },
    {
      icon: Phone,
      name: 'Phone',
      link: {
        href: phone ? `tel:${phone}` : `tel:${HOME_PHONE}`,
        text: phone ?? HOME_PHONE_FORMATTED,
      },
    },
    {
      icon: Envelope,
      name: 'Email',
      link: {
        href: email ? `mailto:${email}` : `mailto:${HOME_EMAIL}`,
        text: email ?? 'team@home.cc',
      },
    },
    {
      icon: WhatsApp,
      name: 'WhatsApp',
      link: {
        href: whatsapp ? `https://wa.me/${whatsapp}` : `https://wa.me/${HOME_WHATSAPP}`,
        text: whatsapp ?? `+${HOME_WHATSAPP_FORMATTED}`,
      },
    },
  ];

  // If hideChat is true, filter out the Live Chat contact (the first item)
  const filteredContactDetails = hideChat ? contactDetails.slice(1) : contactDetails;

  return (
    <section className="mx-auto flex w-full max-w-7xl flex-col items-center gap-8 rounded-3xl bg-home p-12 text-white shadow-homeLarge">
      <div className="grid grid-cols-2 items-start gap-14 lg:grid-cols-5">
        <div className="order-2 col-span-2 flex justify-center lg:col-span-2">
          <div className="w-full max-w-sm overflow-hidden rounded-2xl xl:max-w-none">
            <img src={HomeTeamImage} alt="HomeTeam" className="inline-block" />
          </div>
        </div>
        <div className="order-1 col-span-2 flex flex-col items-center gap-6 lg:order-3 lg:col-span-3">
          <h2 className="text-center text-3xl font-semibold md:text-4xl">
            👋 Speak to your Home team
          </h2>
          <p className="text-center text-lg">
            We&apos;re on hand with everything you need for your home. <br />
            Monday to Friday 9am to 5.30pm.
          </p>
          <div
            className={twMerge(
              'mt-4 grid grid-cols-2 justify-center gap-3 md:grid-cols-4 md:flex-row',
              hideChat ? 'md:grid-cols-3' : 'md:grid-cols-4'
            )}
          >
            {filteredContactDetails.map(contact => (
              <ContactDetail key={contact.name} {...contact} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
