import React, { useState, useEffect } from 'react';

import { Address, MoveType } from '@features/profile/profile.types';

import { TickIsolated } from '@images/icons';

import { Card } from './components/Card';
import { useWizard } from './wizards';

type MoveWizardProps = {
  property: Address;
};

export const MoveWizard = ({ property }: MoveWizardProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [hasActivatedAccordion, setHasActivatedAccordion] = useState<boolean>(false);

  const steps = useWizard(property);

  const toggleSection = (index: number) => {
    setActiveIndex(activeIndex === index ? 0 : index);
    setHasActivatedAccordion(true);
  };

  useEffect(() => {
    const tasks = steps.flatMap(step => step.tasks);
    const firstIncompleteStep = tasks.findIndex(task => task.status !== 'COMPLETED');
    const index = steps.findIndex(step => step.id === tasks[firstIncompleteStep]?.step);
    setActiveIndex(index === -1 ? steps.length - 1 : index);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tasks = steps.flatMap(step => step.tasks);
  const completedTasks = tasks.filter(task => task.status === 'COMPLETED');

  const percentageComplete = (completedTasks.length / tasks.length) * 100;

  return (
    <div className="flex w-full flex-col items-center gap-10" id="move-wizard">
      <section className="mx-auto flex w-full max-w-3xl flex-col items-center gap-5 px-5 md:gap-8">
        <h2 className="text-center text-3xl font-medium">Manage your move</h2>
        <div className="flex items-center gap-4 rounded-full bg-white p-2 pr-4 text-sm font-medium text-primary shadow-homeLarge md:text-base">
          <span className="rounded-full bg-primary px-4 py-1 font-heading text-lg font-medium text-white">
            Progress
          </span>
          <div>
            <span className="font-heading text-xl md:text-2xl">
              {percentageComplete.toFixed(0)}%
            </span>{' '}
            complete
          </div>
          {property.moveType !== MoveType.TENANCY && (
            <div className="flex items-center gap-1">
              <TickIsolated className="h-5 fill-primary" />
              <span>Sale agreed</span>
            </div>
          )}
        </div>
      </section>

      <section className="flex w-full flex-col items-center gap-0">
        {steps.map((section, index) => (
          <Card
            {...section}
            key={index}
            hideBorder={activeIndex - 1 === index || index === steps.length - 1}
            isActive={activeIndex === index}
            onToggle={() => toggleSection(index)}
            topRadius={index === 0}
            bottomRadius={index === steps.length - 1}
            accordionActivated={hasActivatedAccordion}
          />
        ))}
      </section>
    </div>
  );
};
