import { AusExchangeSignpost } from '@components/Signpost';

export const Exchange = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-10">
        <AusExchangeSignpost />
      </div>
    </>
  );
};
