import axios from 'axios';
import { useState, useEffect } from 'react';

import { environmentFor } from '@utils/environment';

export const useBroadbandComparison = (postcode: string) => {
  const [data, setData] = useState<Data | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noDeals, setNoDeals] = useState(false);

  const ENVIRONMENT = environmentFor(window.location.href);

  const API_URL =
    ENVIRONMENT === 'local'
      ? 'http://localhost:8081/api/broadband/quotes'
      : '/api/broadband/quotes';

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const clean = postcode.replace(/\s/g, '').toUpperCase();
        const response = await axios.get(API_URL + `?postcode=${clean}`);

        setData(response.data);

        if (response.data.totalDeals === 0) {
          setNoDeals(true);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (postcode) {
      fetchData();
    }
  }, [postcode, API_URL]);

  return { data, error, loading, noDeals };
};

type Data = {
  products: HomeboxBroadbandProduct[];
  cheapest: HomeboxBroadbandProduct;
  fastest: HomeboxBroadbandProduct;
  totalDeals: number;
};

export type HomeboxBroadbandProduct = {
  cheapestProduct: boolean;
  broadband: boolean;
  broadbandText: string;
  business: boolean;
  contractLength: number;
  credit: number;
  downloadLimit: number;
  downloadSpeed: number;
  effectiveFirstYearCost: number;
  effectiveFullContractCost: number;
  effectiveMonthlyCost: number;
  externalProductId: number;
  externalShortName: string;
  externalSupplierId: number;
  extrasText: string;
  fibre: boolean;
  freeCalls: number;
  fullContractCost: number;
  landLine: boolean;
  monthlyCost: number;
  monthlyCostText: string;
  offerExpiry: string;
  offerGraphicImage: any[]; // Assuming this is an array of any type, modify if you know the specific type
  offerLength: number;
  offerStart: string;
  offerText: string;
  originalMonthlyCost: number;
  originalMonthlyCostText: string;
  originalSetupFee: number;
  outlink: string;
  phoneNumber: string | null; // Phone number can be null
  phoneText: string;
  plusVat: number;
  postageCost: number;
  name: string;
  rating: number | null; // Rating can be null
  setupFee: number;
  student: boolean;
  network: { logo: string };
  switchType: string;
  totalChannels: string;
  tv: boolean;
  tvBundles: any[]; // Assuming this is an array of any type, modify if you know the specific type
  tvChannels: any[]; // Assuming this is an array of any type, modify if you know the specific type
  tvIconText: string;
  tvPackageDescription: string;
  tvPackageImage: string;
  tvText: string;
  uploadSpeed: number;
};
