import { Link } from 'react-router-dom';

import HomeTeamImage from '@images/home-concierge.jpg';
import { Envelope, Phone } from '@images/icons';

interface ContactLink {
  href: string;
  text: string;
}

interface ContactDetailProps {
  icon: React.ElementType;
  name: string;
  link: ContactLink;
}

const ContactDetail = ({ icon: Icon, name, link }: ContactDetailProps) => (
  <div className="flex flex-col items-center gap-2">
    <span className="flex h-10 w-10 items-center justify-center">
      <Icon className="fill-white" />
    </span>
    <h3 className="text-cent pt-2 text-xl font-medium">{name}</h3>
    <Link
      to={link.href}
      rel="noreferrer"
      className="text-center font-light underline hover:no-underline"
    >
      {link.text}
    </Link>
  </div>
);

interface HomeTeamProps {
  email?: string;
  phone?: string;
}

export const HomeTeamBlockAus = ({ email, phone }: HomeTeamProps) => {
  const contactDetails: ContactDetailProps[] = [
    {
      icon: Phone,
      name: 'Phone',
      link: {
        href: phone ? `tel:${phone}` : 'tel:(02) 6618 3388',
        text: phone ?? '(02) 6618 3388',
      },
    },
    {
      icon: Envelope,
      name: 'Email',
      link: {
        href: email ? `mailto:${email}` : 'mailto:McGrath@home.cc',
        text: email ?? 'McGrath@home.cc',
      },
    },
  ];

  return (
    <section className="mx-auto flex w-full max-w-7xl flex-col items-center gap-8 rounded-3xl bg-home p-12 text-white shadow-homeLarge">
      <div className="grid grid-cols-2 items-start gap-14 lg:grid-cols-5">
        <div className="order-2 col-span-2 flex justify-center lg:col-span-2">
          <div className="w-full max-w-sm overflow-hidden rounded-2xl xl:max-w-none">
            <img src={HomeTeamImage} alt="HomeTeam" className="inline-block" />
          </div>
        </div>
        <div className="order-1 col-span-2 flex flex-col items-center gap-6 lg:order-3 lg:col-span-3">
          <h2 className="text-center text-3xl font-semibold md:text-4xl">Contact us 👋</h2>
          <p className="text-center text-lg">
            We&apos;re on hand with everything you need for your home. <br />
            Monday to Friday 9am to 5.30pm.
          </p>
          <div className="mt-4 grid w-full max-w-sm grid-cols-2 justify-center gap-5 md:flex-row">
            {contactDetails.map(contact => (
              <ContactDetail key={contact.name} {...contact} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
