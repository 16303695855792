import { LinkButton } from '@components/Button';
import { ConveyancingSignpost } from '@components/Signpost';

import { BlogLinks } from '@constants/index';

import LeadenhallLawGroup from '@images/chestertons/leadenhall_law_group.png';
import { TickIsolated } from '@images/icons';

import { PartnerBrand } from '@utils/getBrandingConfig';

import { ContentBlock } from '../../components/ContentBlock';

type ConveyancingProps = {
  partnerCode?: string;
};

export const Conveyancing = ({ partnerCode }: ConveyancingProps) => {
  const isChestertons = partnerCode === PartnerBrand.Chestertons;

  if (isChestertons) {
    return (
      <div className="flex flex-col items-start gap-6 px-7 pb-6 md:flex-row md:gap-10 md:px-10">
        <div className="flex flex-col gap-6 md:basis-1/2">
          <h2 className="text-2xl font-medium">
            Chestertons recommends Leadenhall Law Group for conveyancing services due to their
            collaborative 'Sale Ready' program.{' '}
          </h2>
          <ul className="flex flex-col gap-3">
            <li className="flex items-start gap-2">
              <span className="basis-5">
                <TickIsolated className="mr-2 inline-block w-5 fill-black" />{' '}
              </span>

              <span>
                Streamline property transactions by preparing comprehensive legal documentation in
                advance.
              </span>
            </li>

            <li className="flex items-start gap-2">
              <span className="basis-5">
                <TickIsolated className="mr-2 inline-block w-5 fill-black" />{' '}
              </span>

              <span>Reduce the average time to exchange contracts from 120 to 45 days. </span>
            </li>

            <li className="flex items-start gap-2">
              <span className="basis-5">
                <TickIsolated className="mr-2 inline-block w-5 fill-black" />{' '}
              </span>

              <span>
                Additionally, Leadenhall Law Group's commitment to transparency, efficiency, and
                proactive communication aligns with Chestertons' dedication to providing clients
                with a seamless property transaction experience
              </span>
            </li>
          </ul>
          <LinkButton href="https://my.home.cc/redirect/services/conveyancing/leadenhall-law-group" target="_blank">
            Visit Leadenhall Law Group
          </LinkButton>
        </div>
        <img src={LeadenhallLawGroup} alt="Leadenhall Law Group" className="mx-auto mb-10 mt-5" />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="flex flex-col gap-10 md:mt-5 md:basis-1/2 md:gap-14">
          <ConveyancingSignpost />
        </div>
        <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <hr className="w-full border-stone-200 md:hidden" />
          <ContentBlock
            title="What to expect"
            content="Ease of instruction, timely updates on your case, no nasty surprises."
            link={BlogLinks.CONVEYANCING}
            buttonText="Read our guide to conveyancing"
            showBoxOnDesktop
          />
        </div>
      </div>
    </>
  );
};
