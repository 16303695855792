import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { HomeLogo } from '@components/HomeLogo';

import { ApiRoutes } from '@constants/index';

import { resetAll } from '@features/common';
import { selectProfile } from '@features/profile/profile.ts';
import { MoveStatus } from '@features/profile/profile.types';

import { ArrowForward, HouseCross, Padlock, User } from '@images/icons';

import axios from '@utils/customAxios';

interface IHeaderProps {
  loggedIn?: boolean;
}

export const HEADER_HEIGHT = 80;

export const Header = ({ loggedIn }: IHeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector(selectProfile);

  const signOut = async () => {
    const result = await axios.post(ApiRoutes.SIGNOUT, {
      withCredentials: true,
    });

    if (result?.status === 200) {
      dispatch(resetAll());
      navigate('/signin');
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const accountRef = useRef<HTMLButtonElement>(null);

  const handleClick = (e: MouseEvent) => {
    // Only close the dropdown if the click is outside both the account button and dropdown.
    if (
      (accountRef.current && accountRef.current.contains(e.target as Node)) ||
      (dropdownRef.current && dropdownRef.current.contains(e.target as Node))
    ) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <header
      className="relative z-40 mx-auto flex w-full max-w-7xl items-center justify-between px-6 py-2"
      style={{ height: `${HEADER_HEIGHT}px` }}
    >
      {!!loggedIn ? (
        <Link to="/" className="flex items-center gap-5">
          <HouseCross className="h-12 fill-home" />
          <div className="flex flex-col gap-1">
            <span className="text-xl font-medium leading-none">
              Hi {profile.value?.customer.name.firstName}!
            </span>
            {profile.value?.properties &&
              profile.value.properties.length > 0 &&
              profile.value?.properties[0].moveStatus !== MoveStatus.COMPLETED && (
                <span className="text-lg font-medium leading-none">Let's get you moving!</span>
              )}
          </div>
        </Link>
      ) : (
        <HomeLogo />
      )}

      {loggedIn && (
        <nav className="flex items-center gap-7">
          <button
            role="navigation"
            ref={accountRef}
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Main menu"
            data-testid="authenticated-button"
            className={twMerge(
              'group relative flex h-12 w-12 items-center justify-center overflow-hidden rounded-full bg-primary shadow-[rgba(0,0,0,0.9)] transition-shadow ease-out'
            )}
          >
            <span
              className={twMerge(
                'absolute inset-0 scale-0 transform rounded-full bg-gray-500 transition-transform ease-out group-hover:scale-100',
                isOpen && 'scale-100'
              )}
            ></span>
            <User className="relative z-10 h-8 w-8 fill-white" />
          </button>
        </nav>
      )}

      {loggedIn && isOpen && (
        <div
          ref={dropdownRef}
          className="animate-dropdown absolute right-5 top-16 z-50 mt-3 w-full max-w-[200px] origin-top-right rounded-xl bg-white px-2 py-3 shadow-homeLargeDark focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            <button
              onClick={signOut}
              className="group flex w-full items-center justify-between rounded-lg px-5 py-2 text-left text-lg text-gray-700 transition-colors duration-200 hover:bg-gray-50"
              role="menuitem"
            >
              <span>Sign out</span>
              <ArrowForward className="h-5 -translate-x-3 transform fill-black opacity-0 transition-all duration-200 group-hover:translate-x-0 group-hover:opacity-100" />
            </button>
          </div>
        </div>
      )}
    </header>
  );
};
