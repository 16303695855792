import { Map } from '@components/Map';

import { Address } from '@features/profile/profile.types';

import { convertAddressToSingleLine } from '@utils/convertAddress';

type Props = {
  property: Address;
};

export const FindAProperty = ({ property }: Props) => {
  const postcode = property?.postcode || '';
  const address = property ? convertAddressToSingleLine(property) : '';

  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="mx-auto flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <div className="flex w-full max-w-xl flex-col gap-5">
            {postcode && (
              <div className="block h-40 w-full overflow-hidden rounded-xl">
                <Map coordinates={property.coordinates} />
              </div>
            )}
            <div className="flex flex-col gap-1">
              <h4 className="text-xl font-medium">{address}</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
