import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

import { WizardSteps } from '@constants/index';

import { resetAll } from '@features/common';

import { PartnerBrand } from '@utils/getBrandingConfig';

import { fetchMyProfile } from './profile.thunks';
import { ProfileState, TaskStatus } from './profile.types';

const initialState: ProfileState = {
  value: null,
  isFetching: false,
  fetchComplete: false,
  errorOccurred: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // Add this reducer
    updateTaskStatus: (
      state: ProfileState,
      action: PayloadAction<{ propertyId: string; taskId: string; newStatus: TaskStatus }>
    ) => {
      const { propertyId, taskId, newStatus } = action.payload;

      if (state.value) {
        const property = state.value.properties.find(p => p.propertyId === propertyId);
        if (property) {
          const task = property.tasks.find(t => t.id === taskId);
          if (task) {
            task.status = newStatus;
          }
        }
      }
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchMyProfile.pending.type, (state: ProfileState) => {
      state.isFetching = true;
      state.errorOccurred = false;
      delete state.errorStatusCode;
    });
    builder.addCase(fetchMyProfile.fulfilled.type, (state: ProfileState, action: any) => {
      state.isFetching = false;
      state.fetchComplete = true;
      state.errorOccurred = false;
      delete state.errorStatusCode;

      state.value = action.payload;

      if (state.value && state.value.properties) {
        state.value.properties.forEach(property => {
          if (property.partnerCode === PartnerBrand.McGrath && property.tasks) {
            const contractsTask = property.tasks.find(task => task.step === WizardSteps.contracts);
            if (contractsTask) {
              contractsTask.uiName = 'Finances in place';
            }

            const removalsTask = property.tasks.find(task => task.step === WizardSteps.removals);
            if (removalsTask) {
              removalsTask.uiName = 'Organise removal';
            }

            const insuranceTask = property.tasks.find(task => task.step === WizardSteps.insurance);
            if (insuranceTask) {
              insuranceTask.uiName = 'Set up insurance';
            }

            const completeTasks = property.tasks.filter(task => task.step === WizardSteps.complete);
            if (completeTasks.length > 0) {
              completeTasks[0].uiName = 'Get the keys';
            }
            if (completeTasks.length > 1) {
              completeTasks[1].uiName = 'Move in';
            }

            const utilitiesTasks = property.tasks.filter(
              task => task.step === WizardSteps.getMoveReady
            );
            utilitiesTasks[3].uiName = 'Set up Pay TV';

            let completeCount = 0;
            property.tasks = property.tasks.filter(task => {
              if (task.step === WizardSteps.complete) {
                completeCount++;
                return completeCount <= 2;
              }
              return true;
            });

            let moveReadyCount = 0;
            property.tasks = property.tasks.filter(task => {
              if (task.step === WizardSteps.getMoveReady) {
                moveReadyCount++;
                return moveReadyCount <= 4;
              }
              return true;
            });

            let financeCount = 0;
            property.tasks = property.tasks.filter(task => {
              if (task.step === WizardSteps.finances) {
                financeCount++;
                return financeCount <= 1;
              }
              return true;
            });

            const financeTask = property.tasks.find(task => task.step === WizardSteps.finances);
            if (financeTask) {
              financeTask.uiName = 'Finances in place';
            }
          }
        });
      }
    });

    builder.addCase(fetchMyProfile.rejected.type, (state: ProfileState, action: any) => {
      const payloadJson = action.payload;
      const parsedPayload = payloadJson ? JSON.parse(payloadJson) : null;

      state.isFetching = false;
      state.errorOccurred = true;
      state.errorStatusCode = parsedPayload?.status || 500;
    });
    builder.addCase(resetAll, () => initialState);
  },
});

export const { updateTaskStatus } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.me;

export const meReducer = profileSlice.reducer;
