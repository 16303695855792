import { Link } from 'react-router-dom';

import { AusRemovalsSignpost } from '@components/Signpost';

import { ProductLinks } from '@constants/index';

import EnterpriseLogo from '@images/enterprise_logo.webp';
import EnterpriseVan from '@images/enterprise_van.webp';

export const Removals = () => {
  return (
    <div className="flex flex-col items-center gap-10 pb-10">
      <AusRemovalsSignpost />
    </div>
  );
};
