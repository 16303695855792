import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Address, MoveType } from '@features/profile/profile.types';

import { Moving } from '@images/icons';

import { convertAddressToSingleLine } from '@utils/convertAddress';
import { getBrandingConfig } from '@utils/getBrandingConfig';

import { Map } from '../Map';
import { useWizard } from '../MoveWizard/wizards';


type MovingTileProps = {
  property: Address;
  includeLink?: boolean;
};

export const MovingTile = ({ property, includeLink }: MovingTileProps) => {
  const steps = useWizard(property);

  const tasks = steps.flatMap(step => step.tasks);
  const completedTasks = tasks.filter(task => task.status === 'COMPLETED');

  const percentageComplete = (completedTasks.length / tasks.length) * 100;

  const partnerDetails = getBrandingConfig(property.partnerCode);

  const friendlyStatus = () => {
    switch (property.status) {
      case MoveType.BUYER:
        return 'Buying';
      case MoveType.SELLER:
        return 'Selling';
      case MoveType.TENANCY:
        return 'Renting';
      default:
        return '';
    }
  };

  const content = (
    <section
      className={twMerge(
        'relative order-2 col-span-1 flex flex-col gap-4 overflow-hidden rounded-2xl p-5 shadow-homeLarge md:col-span-3'
      )}
    >
      <div
        className={twMerge(
          'absolute left-0 top-0 z-10 h-full w-full transition-all duration-300 group-hover:brightness-[0.85]',
          !!partnerDetails ? partnerDetails.style.bg : 'bg-home'
        )}
      ></div>
      <header className="z-20 flex w-full items-center justify-between">
        <h3 className="text-2xl font-medium text-white sm:text-3xl">{friendlyStatus()}</h3>
        <div className="flex items-center gap-4">
          {includeLink && (
            <span className="text-base text-white underline hover:no-underline">View progress</span>
          )}
          <Moving className="h-8 fill-white" />
        </div>
      </header>
      <main className="z-20 grid grid-cols-1 items-center gap-4 sm:grid-cols-3">
        <div className="col-span-2 flex flex-col gap-1">
          <div className="flex items-end gap-2 text-white">
            <span className="text-3xl font-normal">{percentageComplete.toFixed(0)}%</span>
            <span className="text-lg font-light">completed</span>
          </div>
          <p className="text-base text-white">{convertAddressToSingleLine(property)}</p>
          {!!partnerDetails && (
            <img
              src={partnerDetails.images.monotone.url}
              alt={partnerDetails.title}
              width={partnerDetails.images.monotone.width}
              height={partnerDetails.images.monotone.width}
            />
          )}
        </div>
        <div className="col-span-1 flex h-full min-h-[160px] items-center justify-center overflow-hidden rounded-xl">
          <Map coordinates={property.coordinates} />
        </div>
      </main>
      <div className="z-20 flex h-1.5 w-full rounded-full bg-[rgba(255,255,255,0.3)]">
        <div
          style={{ width: `${percentageComplete}%` }}
          className={twMerge(
            'h-full rounded-full bg-white',
            percentageComplete === 100 && 'w-full'
          )}
        />
      </div>
    </section>
  );

  return includeLink !== undefined ? (
    <Link
      to={`/property/${property.propertyId}`}
      className="group order-2 col-span-1 mx-auto w-full rounded-2xl transition-all duration-500 md:col-span-3"
    >
      {content}
    </Link>
  ) : (
    <div className="w-full">{content}</div>
  );
};
