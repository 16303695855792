import { Link } from 'react-router-dom';

import { ProductLinks } from '@constants/index';

import { Bulb } from '@images/icons';

export const Energy = () => {
  return (
    <div className="flex w-full flex-col items-center gap-5 md:items-start">
      <h4 className="flex flex-col items-center justify-center gap-4 text-2xl font-medium md:flex-row">
        <Bulb className="h-10 w-10 fill-primary" />
        <span className="text-center md:text-left">Find your best energy quote</span>
      </h4>
      <p>
        Home can help find the perfect tariff for your place. And even makes switching a breeze.
        Switch yourself below or speak to the Home team and we'll do the hard work for you.
      </p>
      <Link
        to={ProductLinks.ENERGY}
        target="_blank"
        rel="noreferrer"
        className="flex w-auto gap-3 rounded-2xl border-2 border-black bg-black px-5 py-1 text-center font-medium text-white transition-all hover:border-gray-700 hover:bg-gray-700 md:px-10 md:py-3"
      >
        Set up my energy
      </Link>
    </div>
  );
};
