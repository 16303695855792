import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@components/Button';
import { Signpost } from '@components/Signpost';
import { Spinner } from '@components/Spinner';

import { ApiRoutes } from '@constants/index';

import PoweredByCompareconnect from '@images/australia/powered_by_compareconnect.png';

import customAxios from '@utils/customAxios';

interface LeadResponse {
  success: boolean;
  url: string;
  publicKey: string;
}

type InsuranceProps = {
  propertyId: string;
};

const dobSchema = z.object({
  dob: z.string().min(1, 'Date of birth is required'),
});

type FormValues = z.infer<typeof dobSchema>;

export const Insurance = ({ propertyId }: InsuranceProps) => {
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(dobSchema),
  });

  const onSubmit = async (data: FormValues) => {
    setIsSubmitting(true);
    setErrorOccured(false);

    try {
      const payload = { propertyId, dob: data.dob };
      const response = await customAxios.post<LeadResponse>(
        ApiRoutes.AUSTRALIA_INSURANCE,
        payload,
        { withCredentials: true }
      );

      if (response?.data?.url) {
        // Open the link in a new tab immediately after a successful response.
        window.open(response.data.url, '_blank');
      }
    } catch (error) {
      setErrorOccured(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-10 px-5 sm:px-10">
      <Signpost
        subtitle="To do"
        title="Find, compare and set up your home and contents insurance"
        hideIcon
      >
        <p>
          View quotes and compare policies from leading providers to get the best cover for your new
          home.
        </p>

        {!isSubmitting && !errorOccured && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto flex h-72 max-w-sm flex-col items-center justify-center gap-4"
          >
            <div className="flex w-full flex-col items-center">
              <label htmlFor="dob" className="mb-1 text-center font-medium">
                Enter your date of birth to get started
              </label>
              <input
                type="date"
                id="dob"
                {...register('dob')}
                className="max-w-64 rounded border border-gray-300 p-2 text-lg"
              />
              {errors.dob && <p className="text-sm text-red-500">{errors.dob.message}</p>}
            </div>
            <p className="text-center text-sm">
              By submitting you consent to your details being sent to Compare and Connect to find
              you the best insurance options.
            </p>
            <Button disabled={isSubmitting}>Get started</Button>
            <img
              src={PoweredByCompareconnect}
              alt="Powered by Compareconnect"
              className="max-w-[235px]"
            />
          </form>
        )}

        {isSubmitting && (
          <div className="flex h-72 items-center justify-center">
            <Spinner colour="black" />
          </div>
        )}

        {!isSubmitting && errorOccured && (
          <div className="flex h-72 items-center justify-center">
            <div className="text-center font-medium text-red-500">
              An error occurred while submitting your details. Please try again later.
            </div>
          </div>
        )}
      </Signpost>
    </div>
  );
};
