import { useState } from 'react';

import { MortgageSignpost, SpringtideSignpost } from '@components/Signpost';

import { BlogLinks } from '@constants/index';

import { environmentFor } from '@utils/environment';
import { PartnerBrand } from '@utils/getBrandingConfig';

import { ContentBlock } from '../../components/ContentBlock';

type FinancesProps = {
  partnerCode?: string;
};

export const Finances = ({ partnerCode }: FinancesProps) => {
  const ENVIRONMENT = environmentFor(window.location.href);
  const isDevEnvironment = ['dev', 'local', 'staging'].includes(ENVIRONMENT);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isChestertons = partnerCode === PartnerBrand.Chestertons;

  // Remove this when the springtide endpoint is ready
  const queryParams = new URLSearchParams(window.location.search);
  const showParam = queryParams.get('show');

  // Remove this when the springtide endpoint is ready and replace with isChestertons variable
  const shouldShowChestertons = isChestertons && showParam === 'springtide';

  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="flex flex-col gap-10 md:mt-5 md:basis-1/2 md:gap-14">
          {shouldShowChestertons ? (
            <SpringtideSignpost isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
          ) : (
            <MortgageSignpost
              isDevEnvironment={isDevEnvironment}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          )}
        </div>
        <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <hr className="w-full border-stone-200 md:hidden" />
          <ContentBlock
            title="What to expect"
            content={`An expert adviser from ${shouldShowChestertons ? 'Springtide Capital' : 'SPF'} will contact you to have a transparent conversation about your circumstances and requirements. If you proceed, they will then help progress your mortgage from application to completion and beyond.`}
            link={BlogLinks.MORTGAGE_PROCESS}
            buttonText="Read our guide to the mortgage process"
            showBoxOnDesktop
          />
        </div>
      </div>
    </>
  );
};
