import { getBrandingConfig } from '@utils/getBrandingConfig';

export const ApiRoutes = {
  AUTH_CHECK: '/api/home/auth/check',
  HOME_INVITATION: '/api/home/invitation',
  PROFILE: '/api/home/me',
  CONFIRM_PASSWORD: '/api/home/confirm-password',
  MAYBE_FORGOT_PASSWORD: '/api/home/maybe-forgot-password',
  CONFIRM_FORGOT_PASSWORD: '/api/home/confirm-forgot-password',
  ONBOARD_USER: (id: string, email: string, leadId: string, sessionId: string) =>
    `/api/home/onboard?id=${id}&email=${email}&lead-id=${leadId}&sessionId=${sessionId}`,
  SIGNIN: '/api/home/auth/signin',
  SIGNUP: '/api/home/customers/signup/form',
  SIGNOUT: '/api/home/auth/signout',
  UPDATE_TASK: (propId: string, taskId: string) => `/api/home/properties/${propId}/tasks/${taskId}`,
  LOG: '/api/events',
  CHAT: '/api/home/hi/message',
  CHAT_HISTORY: '/api/home/hi/messages',
  OXYGEN: '/api/home/oxygen/send-lead',
  AUSTRALIA_UTILITIES: '/api/home/australia/utilities',
  AUSTRALIA_INSURANCE: '/api/home/australia/insurance',
};

export enum WizardPaths {
  saleAgreed = 'sale-agreed',
  findAProperty = 'find-a-property',
  finance = 'finance',
  conveyancing = 'conveyancing',
  signContracts = 'sign-contracts',
  exchangeContracts = 'exchange-contracts',
  insurance = 'insurance',
  removals = 'removals',
  energy = 'energy',
  broadband = 'broadband',
  getReady = 'get-ready',
  complete = 'complete',
}

export enum WizardSteps {
  findProperty = 'FIND_PROPERTY',
  saleAgreed = 'SALE_AGREED',
  conveyancing = 'CONVEYANCING',
  contracts = 'CONTRACTS',
  insurance = 'INSURANCE',
  exchange = 'EXCHANGE',
  removals = 'REMOVALS',
  getMoveReady = 'MOVE_READY',
  complete = 'COMPLETE',
  finances = 'FINANCES',
}

export const ProductLinks = {
  BUYER_INSURANCE: 'https://home.rhinohomeprotect.com/quote/',
  SELLER_INSURANCE: 'https://home.rhinohomeprotect.com/quote/',
  CONVEYANCING: '/services/conveyancing',
  STORE: {
    MAIN: 'https://store.home.cc/',
    SECURITY: 'https://store.home.cc/collections/home-security',
    PACKING_SUPPLIES: 'https://store.home.cc/collections/packing-supplies',
    PRODUCTS: {
      MOVING_PACK_3_BED:
        'https://store.home.cc/collections/packing-supplies/products/2-3-bedroom-removal-pack-40-boxes',
    },
  },
  HOME_INSURANCE: 'https://www.quotezone.co.uk/SetAffiliate.php?aid=W6U8R4-004&type=home',
  MORTGAGE: '',
  REMOVALS_ANYVAN: 'https://www.anyvan.com/partners/home',
  REMOVALS_ENTERPRISE: 'https://partners.rentalcar.com/home/',
  TENANTS_INSURANCE_DIGITAL_JOURNEY: 'https://www.safehouse-getquote.co.uk/TCI?referralRef=',
  TENANTS_INSURANCE: 'https://calendly.com/home-runners/tenants-insurance',
  BUNDLES: 'https://app.homebox.co.uk/home/setup-account',
  SERVICES: 'https://www.home.cc/services',
  REQUEST_CHAT: 'https://calendly.com/home-runners/speak-to-the-team',
  REQUEST_CHAT_REMOVALS: 'https://calendly.com/home-runners/removals-quotation',
  ENERGY: 'https://domestic.home-cc.tickd.co.uk/',
};

export const ProductLinksAus = {
  REMOVALS_MUVAL:
    'https://www.muval.com.au/partners/compare-and-connect?ref=compareconnect&utm_source=referral&utm_campaign=affiliate&mvse=bbqvtafc0jk7cnv6j5gh6vb',
};

/**
 * Google doc with codes:
 * https://docs.google.com/spreadsheets/d/1sRE3F06rkGS0Idmn5VVOkwvjyh576Jb1bE0rMXfFNtk/edit?gid=0#gid=0
 */
export const getTenantInsuranceLink = (ref: string) => {
  const brandConfig = getBrandingConfig(ref);

  if (brandConfig?.trackedLinks?.safehouseInsurance) {
    return brandConfig.trackedLinks.safehouseInsurance;
  } else {
    return `${ProductLinks.TENANTS_INSURANCE_DIGITAL_JOURNEY}home-t13`;
  }
};

export const Routes = {
  TERMS: '/terms',
  PRIVACY: '/privacy',
  SIGNIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_FORGOT_PASSWORD: '/confirm-forgot-password',
  UPDATE_PASSWORD: '/update-password',
  INVITATIONS: '/invitations',
  CONFIRMATION: '/confirmation',
  SERVICES_CONVEYANCING: '/services/conveyancing',
  SERVICES_BROADBAND: '/services/broadband',
  HOME: '/',
  CHAT: '/chat',
  SIGNUP: '/signup',
  HOME_INTELLIGENCE: '/home-intelligence',
  // landing pages
  LANDING_PAGE_CHESTERTONS: '/welcome/chestertons',
  MOVE_PAGE: '/property/:id',
  WELCOME: '/welcome/:id',
  AUSTRALIA_TERMS: '/aus/terms',
  AUSTRALIA_PRIVACY: '/aus/privacy',
};

export const BlogLinks = {
  CONVEYANCING: '',
  MORTGAGE_CALCULATOR: '',
  MORTGAGE_PROCESS: '',
  INSURANCE: '',
  EXCHANGE: '',
  COMPLETE: '',
  TENANTS_INSURANCE: '',
  REMOVALS: '',
};

export const GET_ADDRESS_API_KEY = 'JWLb1LMajUmU0Yhf_T7V-A35320';

export enum TrackingEventType {
  PAGE_VIEW_EVENT,
  SIGNUP_FORM_EVENT,
  EMAIL_SENT_EVENT,
}

export const SocialLinks = {
  FACEBOOK: 'https://www.facebook.com/fourhappywalls.cc/',
  INSTAGRAM: 'https://www.instagram.com/fourhappywalls.cc/',
  LINKEDIN: 'https://www.linkedin.com/company/homecc/',
  WHATSAPP: 'https://wa.me/447535742914',
};

export const HOME_EMAIL = 'team@home.cc';
export const HOME_PHONE_FORMATTED = '03300 433 828';
export const HOME_WHATSAPP_FORMATTED = '44 7535 742914';
export const HOME_PHONE = '03300433828';
export const HOME_WHATSAPP = '447535742914';
