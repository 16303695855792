import { useState } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Button, LinkButton } from '@components/Button';

import {
  ApiRoutes,
  getTenantInsuranceLink,
  ProductLinks,
  ProductLinksAus,
  WizardSteps,
} from '@constants/index';

import AusMortgageRates from '@images/australia/aus_mortgage_rates.png';
import Edmonds from '@images/australia/edmonds.png';
import Mulcahy from '@images/australia/mulcahy.png';
import PoweredByCompareconnect from '@images/australia/powered_by_compareconnect.png';
import PoweredByOxygen from '@images/australia/powered_by_oxygen.png';
import SpringtideCapial from '@images/chestertons/springtide_capital.png';
import DefaqtoLogo from '@images/defaqto.png';
import { HouseCross } from '@images/icons';
import insuranceProviders from '@images/InsuranceProviders.webp';

import customAxios from '@utils/customAxios';

type SignpostProps = {
  subtitle: string;
  title: string;
  price?: number;
  text?: string;
  image?: JSX.Element;
  buttonText?: string;
  buttonLink?: string;
  onButtonClick?: (e: any) => any;
  newTab?: boolean;
  children?: React.ReactNode;
  fullWidth?: boolean;
  disableButton?: boolean;
  openCard?: WizardSteps;
  hideIcon?: boolean;
};

export const Signpost = ({
  subtitle,
  title,
  price,
  image,
  buttonText,
  buttonLink,
  onButtonClick,
  text,
  newTab,
  children,
  fullWidth,
  disableButton,
  openCard,
  hideIcon,
}: SignpostProps) => {
  return (
    <div
      className={twMerge(
        'md:max-w-auto mx-auto flex w-full flex-col gap-5 rounded-3xl',
        fullWidth ? 'max-w-full' : 'max-w-xl'
      )}
    >
      <div className="flex w-full items-start justify-between gap-3 md:gap-8">
        <div className="flex flex-col gap-2">
          <span className="font-bold">{subtitle}</span>
          <h4 className="text-2xl font-medium">
            {title}
            {!!price && (
              <>
                {' '}
                £{price}
                <span className="text-lg">.00</span>
              </>
            )}
          </h4>
        </div>
        {!hideIcon && (
          <span className="flex h-12 w-12 min-w-12 items-center justify-center rounded-full bg-home">
            <HouseCross className="fill-white" height="28px" />
          </span>
        )}
      </div>
      {children}
      <div className="flex w-full flex-col items-center gap-8 md:items-start">
        {text && <p className="text-left text-base">{text}</p>}
        {image && <div className="flex w-full justify-center">{image}</div>}

        {!!buttonText && (
          <a
            className={twMerge(
              'font-base w-full cursor-pointer rounded-xl bg-primary px-5 py-3 text-center text-lg font-medium text-white sm:w-auto md:w-full',
              disableButton ? 'cursor-not-allowed bg-[#E2E2E2]' : 'hover:bg-primary-dark'
            )}
            {...(!!buttonLink && { href: buttonLink })}
            {...(!!openCard && {
              onClick: () => {
                const card = document.getElementById(`${openCard}-header`);
                card?.click();
              },
            })}
            {...(!!onButtonClick && { onClick: onButtonClick })}
            aria-disabled={disableButton}
            target={newTab ? '_blank' : ''}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        )}
      </div>
    </div>
  );
};

export const BuyerProtectionSignpost = () => (
  <Signpost
    subtitle="Protect your move"
    title="Home Buyers Protection from"
    price={74}
    text="Did you know you can cover your legal, survey and mortgage costs should your purchase fall through."
    buttonText="Protect your move fees"
    buttonLink={ProductLinks.BUYER_INSURANCE}
    newTab
  />
);

export const SellerProtectionSignpost = () => (
  <Signpost
    subtitle="Protect your move"
    title="Home Sellers Protection from"
    price={69}
    text="Did you know you can cover your legal costs should your sale fall through."
    buttonText="Protect your move fees"
    buttonLink={ProductLinks.SELLER_INSURANCE}
    newTab
  />
);

export const EnergySignpost = () => (
  <Signpost
    subtitle="Get move ready"
    title="Save on your energy bills"
    text="Home can help find the perfect tariff for your place in less than 10 minutes, and even makes switching a breeze."
    buttonText="View energy tariffs"
    buttonLink="/wizard/energy/"
    newTab
  />
);

export const BroadbandSignpost = () => (
  <Signpost
    subtitle="Get move ready"
    title="Set up broadband"
    text="Find the right broadband deal for you and get set up in less than 5 minutes."
    buttonText="View broadband deals"
    buttonLink="/wizard/broadband/"
    newTab
  />
);

export const ConveyancingSignpost = () => (
  <Signpost
    subtitle="To do"
    title="Instruct one of our trusted conveyancing partners"
    buttonText="Get free conveyancer quotes"
    buttonLink={ProductLinks.CONVEYANCING}
    newTab
  >
    <p className="font-light">
      Find the right conveyancer for your move with conveyancing packages designed for you. We shave
      an average of 60 days off the time it takes to complete.
    </p>
  </Signpost>
);

export const MortgageSignpost = ({
  isDevEnvironment,
  isSubmitting,
  setIsSubmitting,
}: {
  isDevEnvironment: boolean;
  isSubmitting: boolean;
  setIsSubmitting: any;
}) => (
  <Signpost
    subtitle="To do"
    title="Find the right mortgage deal for you"
    buttonText="Ask SPF to contact me"
    onButtonClick={(e: any) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      fetch('/api/home/spf/send-lead', { method: 'POST' })
        .then(res => (e.target.innerText = 'SPF will be in touch with you soon!'))
        .catch(err => (e.target.innerText = "That didn't work, try again later"));
    }}
  >
    <p className="font-light">
      Home partners with SPF Private Clients, a leading UK mortgage broker who for over 25 years
      have been helping clients to turn their property dreams into reality. They are independent and
      can access the whole market, working with a wide number of lenders.
    </p>
  </Signpost>
);

export const SpringtideSignpost = ({
  isSubmitting,
  setIsSubmitting,
}: {
  isSubmitting: boolean;
  setIsSubmitting: any;
}) => (
  <Signpost
    subtitle="To do"
    title="Find the right mortgage deal for you"
    buttonText="Ask Springtide Capital to contact me"
    onButtonClick={(e: any) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      fetch('/api/home/springtide/send-lead', { method: 'POST' })
        .then(res => (e.target.innerText = 'Springtide Capital will be in touch with you soon!'))
        .catch(err => (e.target.innerText = "That didn't work, try again later"));
    }}
  >
    <img src={SpringtideCapial} alt="Springtide Capital" className="max-w-xs" />
  </Signpost>
);

export const InsuranceSignpost = () => (
  <Signpost
    subtitle="To do"
    title="View quotes from over 50 UK insurance providers, including"
    image={<img src={insuranceProviders} alt="Insurance" className="max-w-full md:max-w-[400px]" />}
    buttonText="Get Home Insurance"
    buttonLink={ProductLinks.HOME_INSURANCE}
    newTab
  />
);

export const InsuranceReminderSignpost = () => (
  <Signpost
    subtitle="Don't forget"
    title="You need to have buildings insurance in place before exchanging"
    buttonText="Find the best insurance policy for you"
    buttonLink={ProductLinks.HOME_INSURANCE}
    newTab
  />
);

export const TenantsInsuranceSignpost = ({ agent }: any) => (
  <Signpost subtitle="To do" title="Get peace of mind with 5* Defaqto rated tenant insurance">
    <p>
      Protect your belongings at your new home and get covered with Safe House Insurance. Made for
      renters, it offers top-quality insurance with a <strong>5* Defaqto rating.</strong>
    </p>
    <div className="flex items-center gap-4 py-2">
      <img src={DefaqtoLogo} alt="Defaqto" className="w-24" />
      <span className="font-heading text-lg">Insurance made for renters</span>
    </div>
    <div className="flex flex-col items-start gap-5">
      <Link
        to={getTenantInsuranceLink(agent || '')}
        target="_blank"
        className="button-animate rounded-xl px-10 py-3 font-medium"
        rel="noreferrer"
      >
        Get a quote online
      </Link>
      <Link
        to={ProductLinks.TENANTS_INSURANCE}
        target="_blank"
        className="py-1 font-medium underline hover:no-underline"
        rel="noreferrer"
      >
        Request a callback to discuss your needs
      </Link>
    </div>
  </Signpost>
);

export const RemovalsSignpost = ({ children }: { children: any }) => (
  <Signpost
    subtitle="To do"
    title="Book your removals"
    text="Home's got lorry loads of choice. Pick one of our reliable partners, and book them in. Easy."
  >
    {children}
  </Signpost>
);

export const HomeTeamReminderSignpost = () => (
  <Signpost
    subtitle="Don't forget"
    title="We're on hand to help with anything you might need while moving and settling in"
    buttonText="Speak to your home team"
    buttonLink="#home-team"
  />
);

export const AusFinancesSignpost = ({ hideStats }: { hideStats?: boolean }) => {
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await customAxios.post(ApiRoutes.OXYGEN);
      if (response.status === 200) {
        setMessage('Submitted, a member of the team will be in touch shortly');
      }
    } catch (error) {
      console.error('Error submitting:', error);
      setMessage('Something went wrong, please refresh and try again');
    }
  };

  return (
    <Signpost
      subtitle="To do"
      title="Find your best home loan deal, fast and hassle free with Oxygen"
      hideIcon
    >
      <div className="flex flex-col items-center gap-5">
        <p>We compare hundreds of loans for you from our network of lenders.</p>
        {!hideStats && (
          <img src={AusMortgageRates} alt="Mortgage rates" className="max-w-[413px]" />
        )}
        {message ? (
          <div className="flex min-h-[56px] items-center justify-center">
            <p className="text-center">{message}</p>
          </div>
        ) : (
          <Button onClick={handleSubmit}>Talk to an expert</Button>
        )}
        <img src={PoweredByOxygen} alt="Powered by Oxygen" className="max-w-[266px]" />
      </div>
    </Signpost>
  );
};

export const AusRemovalsSignpost = () => (
  <Signpost subtitle="To do" title="Find and book a trusted removalist with Muval" hideIcon>
    <div className="flex flex-col items-center gap-5">
      <p>There's no better way to book removalists. Compare prices & book online instantly.</p>
      <LinkButton href={ProductLinksAus.REMOVALS_MUVAL}>Get instant prices</LinkButton>
      <img
        src={PoweredByCompareconnect}
        alt="Powered by Compare & Connect"
        className="max-w-[235px]"
      />
    </div>
  </Signpost>
);

export const AusUtilitiesSignpost = () => (
  <Signpost
    subtitle="To do"
    title="Set up your home utilities and broadband with our partners Compare and Connect"
    hideIcon
  />
);

export const AusCompleteSignpost = () => (
  <Signpost
    subtitle="What to expect"
    title="The big day! Your moment to finally relax as you get the keys to your new home"
    hideIcon
  >
    <p>
      Your moment to finally relax as the conveyancing process has finished and you move into your
      new place.
    </p>

    <p>Ownership of the property is transferred along with funds and you will receive the keys.</p>
  </Signpost>
);

export const AusExchangeSignpost = () => (
  <Signpost
    subtitle="What to expect"
    title="This is where it becomes official"
    text="The solicitor's will swap signed copies of the contract. This makes it a legally-binding contract and you really start to celebrate."
    hideIcon
  />
);

export const AusConveyancingSignpost = () => (
  <Signpost subtitle="To do" title="Instruct a conveyancer" hideIcon>
    <p className="font-light">
      McGrath recommends the following leading local conveyancers to assist you with you move:
    </p>
    <div className="flex flex-col gap-4 pt-6">
      <img src={Edmonds} alt="Edmonds Conveyancing" className="w-full max-w-[112px]" />
      <h5 className="text-xl font-medium">Edmonds Conveyancing</h5>
      <p className="font-light">
        Expert conveyancing services to people buying and selling properties across the whole of New
        South Wales.
      </p>
      <LinkButton href="https://edmondsco.com.au/" target="_blank">
        Find out more
      </LinkButton>
    </div>
    <div className="flex flex-col gap-4 pt-6">
      <img src={Mulcahy} alt="Mulcahy Lawyers & Conveyancing" className="w-full max-w-[76px]" />
      <h5 className="text-xl font-medium">Mulcahy Lawyers &amp; Conveyancers</h5>
      <p className="font-light">
        Locally experienced Licensed Conveyancers, Tracey Hamilton LLB, and Carmen Fleming, have
        combined 50 years of experience to make your purchase or sale as stress free as possible.
      </p>
      <LinkButton href="https://atlaw.com.au/" target="_blank">
        Find out more
      </LinkButton>
    </div>
  </Signpost>
);

export const AusInsuranceSignpost = () => (
  <Signpost
    subtitle="To do"
    title="Find, compare and set up your home and contents insurance"
    hideIcon
  >
    <p>
      View quotes and compare policies from leading providers to get the best cover for your new
      home.
    </p>
  </Signpost>
);

export const AusWhatsAppSignpost = ({ link }: { link: string }) => (
  <Signpost
    subtitle="Don't forget"
    title="We're on hand to help with anything you might need while moving and settling in"
    buttonText="Reach out on WhatsApp"
    buttonLink={link}
    newTab
    hideIcon
  />
);
