import { UnknownAction } from '@reduxjs/toolkit';
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThunkDispatch } from 'redux-thunk';

import { PageLoading } from '@components/PageLoading';

import { Authenticated } from '@templates/Authenticated';
import { Common } from '@templates/Common';
import { Unauthenticated } from '@templates/Unauthenticated';

import 'react-toastify/dist/ReactToastify.css';

import { LiveChat } from './livechat';
import { NotFound } from './pages/notFound';
import { routeConfig, routeRedirects } from './routeConfig';
import { ScrollToTop } from './scrollToTop';
import { RootState } from './store';
import { handleResizeObserverError } from './utils/handleResizeObserverError';

export type AppThunkDispatch = ThunkDispatch<RootState, any, UnknownAction>;

export const App = () => {
  // Catching "ResizeObserver loop limit exceeded" error, which is caused by Homebox
  useEffect(() => {
    handleResizeObserverError();
  }, []);

  return (
    <main data-theme="default" className="bg-secondary">
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop />
        <LiveChat />
        <Suspense fallback={<PageLoading />}>
          <Routes>
            {routeConfig.map((route, index) => {
              const RouteComponent = route.component;

              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    !!route.private && !!route.public ? (
                      <Common title={route.title} component={<RouteComponent />} />
                    ) : !!route.private ? (
                      <Authenticated title={route.title} component={<RouteComponent />} />
                    ) : (
                      <Unauthenticated title={route.title} component={<RouteComponent />} />
                    )
                  }
                />
              );
            })}
            {routeRedirects.map((route, index) => (
              <Route key={index} path={route.from} element={<Navigate replace to={route.to} />} />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </main>
  );
};
